import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="prestige-classes"></a><h2>Prestige Classes</h2>
    <a id="eldritch-knight"></a><h3>ELDRITCH KNIGHT</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p className="initial"><a href="http://www.wizards.com/dnd/images/dmg35_gallery/DMG35_PG187_WEB.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><b>Hit Die</b>:
d6.</p>
    <a id="eldritch-knight-requirements"></a><h6>Requirements</h6>
    <p className="initial">To qualify to become an eldritch knight, a
character must fulfill all the following criteria.</p>
    <p><b>Weapon Proficiency</b>: Must be proficient with all martial
weapons.</p>
    <p><b>Spells</b>: Able to cast 3rd-level arcane spells.</p>
    <h6>Class Skills</h6>
    <p className="initial">The eldritch knight&#8217;s class skills (and the
key ability for each skill) are <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#concentration">Concentration</a>
(Con), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#craft">Craft</a>
(Int), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#decipher-script">Decipher Script</a> (Int), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#jump">Jump</a>
(Str), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#knowledge">Knowledge</a> (arcana / nobility and
royalty) (Int), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#ride">Ride</a> (Dex), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#sense-motive">Sense
Motive</a> (Wis), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#spellcraft">Spellcraft</a> (Int), and <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#swim">Swim</a>
(Str). </p>
    <p><b>Skill Points at Each Level</b>: 2 + Int modifier.</p>
    <a id="table-the-eldritch-knight"></a><p><b>Table: The Eldritch Knight</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "60%"
    }}>
      <tbody>
        <tr valign="bottom">
          <th style={{
            "width": "9%"
          }}>Level</th>
          <th style={{
            "width": "15%"
          }}>Base
Attack Bonus</th>
          <th style={{
            "width": "9%"
          }}>Fort
Save</th>
          <th style={{
            "width": "9%"
          }}>Ref
Save</th>
          <th style={{
            "width": "9%"
          }}>Will
Save</th>
          <th>Special</th>
        </tr>
        <tr className="odd-row">
          <td>1st</td>
          <td>+1</td>
          <td>+2</td>
          <td>+0</td>
          <td>+0</td>
          <td>Bonus feat</td>
        </tr>
        <tr>
          <td>2nd</td>
          <td>+2</td>
          <td>+3</td>
          <td>+0</td>
          <td>+0</td>
          <td>+1 level of existing arcane spellcasting class</td>
        </tr>
        <tr className="odd-row">
          <td>3rd</td>
          <td>+3</td>
          <td>+3</td>
          <td>+1</td>
          <td>+1</td>
          <td>+1 level of existing arcane spellcasting class</td>
        </tr>
        <tr>
          <td>4th</td>
          <td>+4</td>
          <td>+4</td>
          <td>+1</td>
          <td>+1</td>
          <td>+1 level of existing arcane spellcasting class</td>
        </tr>
        <tr className="odd-row">
          <td>5th</td>
          <td>+5</td>
          <td>+4</td>
          <td>+1</td>
          <td>+1</td>
          <td>+1 level of existing arcane spellcasting class</td>
        </tr>
        <tr>
          <td>6th</td>
          <td>+6</td>
          <td>+5</td>
          <td>+2</td>
          <td>+2</td>
          <td>+1 level of existing arcane spellcasting class</td>
        </tr>
        <tr className="odd-row">
          <td>7th</td>
          <td>+7</td>
          <td>+5</td>
          <td>+2</td>
          <td>+2</td>
          <td>+1 level of existing arcane spellcasting class</td>
        </tr>
        <tr>
          <td>8th</td>
          <td>+8</td>
          <td>+6</td>
          <td>+2</td>
          <td>+2</td>
          <td>+1 level of existing arcane spellcasting class</td>
        </tr>
        <tr className="odd-row">
          <td>9th</td>
          <td>+9</td>
          <td>+6</td>
          <td>+3</td>
          <td>+3</td>
          <td>+1 level of existing arcane spellcasting class</td>
        </tr>
        <tr>
          <td className="last-row">10th</td>
          <td className="last-row">+10</td>
          <td className="last-row">+7</td>
          <td className="last-row">+3</td>
          <td className="last-row">+3</td>
          <td className="last-row">+1 level of existing arcane
spellcasting class</td>
        </tr>
      </tbody>
    </table>
    <a id="eldritch-knight-class-features"></a><h6>Class Features</h6>
    <p className="initial">All of the following are features of the
eldritch knight prestige class.</p>
    <p><b>Weapon and Armor Proficiency</b>: Eldritch knights gain no
proficiency with any weapon or armor.</p>
    <p><b>Bonus Feat</b>: At 1st level, an eldritch knight may choose
a bonus feat from the list of feats available to fighters. This is in
addition to the feats that a character of any class normally gets from
advancing levels. The character must still meet any prerequisites for
these bonus feats, including levels of fighter for the Weapon
Specialization, Greater Weapon Focus, and Greater Weapon Specialization
feats.</p>
    <p><b>Spells per Day</b>: From 2nd level on, when a new eldritch
knight level is gained, the character gains new spells per day as if
she had also gained a level in whatever arcane spellcasting class she
belonged to before she added the prestige class. She does not, however,
gain any other benefit a character of that class would have gained.
This essentially means that she adds the level of eldritch knight to
the level of whatever other arcane spellcasting class the character
has, then determines spells per day and caster level accordingly.</p>
    <p>If a character had more than one arcane spellcasting class
before she became an eldritch knight, she must decide to which class
she adds each level of eldritch knight for the purpose of determining
spells per day.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      